body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
body {
  font-family: 'Helvetica', sans-serif;
  
}
body {
  overflow: hidden;
}

/* Enable scrolling on the y-axis */
body {
  overflow-y: auto;
}


.App {
  padding:0px;
  background-color: #fff;
  overflow: hidden;
  
}

h1, h2 {
  color: #333;
}

label {
  display: block;
  margin-bottom: 10px;
  font-size: 16px;
  color: #555;
}

input {
  width: 100%;
  padding: 12px;
  margin-top: 5px;
  margin-bottom: 15px;
  box-sizing: border-box;
  text-transform: uppercase;
  border-radius: 8px;
  font-size: 16px;
  outline: none;
  transition: border-color 0.3s;
}

input:focus {
  border-color: #27ae60;
}

button {
  background-color: #232323;
  color: #fff;
  padding: 15px 25px;
  cursor: pointer;
  border: none;
  border-radius: 8px;
  font-size: 18px;
  transition: background-color 0.3s;
}

button:hover {
  background-color: #00000072;
}

p {
  margin: 15px 0;
  font-size: 16px;
}

.loading {
  text-align: center;
  margin: 20px;
  font-size: 18px;
  color: #555;
}

.CertificateDetail {
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 12px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.CertificateDetail p {
  margin: 12px 0;
  font-size: 16px;
  color: #333;
}

.error{
  color: #ff0000;
}
.image{
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.6);
  width: 100%;
}
.validate {
  max-width: 400px;
  text-align: center;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
  margin-bottom: 10px;
  /* top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); */
}
.cert-detail {
  max-width: 400px;
  text-align: center;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
  margin-bottom: 10px;
}


.remarks{
  color: #ff0000;
}

.cid{
  margin-top: 15px;
  margin-bottom: -4px;
  
}
h2 {
  color: #333;
}

.image {
  max-width: 100%;
  height: auto;
  margin-top: 10px; /* Adjust margin as needed */
}

p {
  margin: 10px 0;
  color: #555;
}

/* Optional: Style for Valid and Invalid status */
.logo{
  width:45% ;
}
.logo1{
  margin-bottom: 20px;
  width:70% ;
}

/* ErrorPopup component styling */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}

.modal {
  max-width: 400px;
  width: 100%;
  text-align: center;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: #fff;
  z-index: 1000;
}

.custom-error-modal {
  display: flex;
  flex-direction: column;
}

.custom-error-modal-content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.custom-error-modal-button {
  background-color: #232323;
  color: #fff;
  padding: 10px 15px;
  cursor: pointer;
  border: none;
  border-radius: 8px;
  font-size: 16px;
  margin-top: 10px;
  transition: background-color 0.3s;
}

.custom-error-modal-button:hover {
  background-color: #00000072;
}

body {
  font-family: 'Arial', sans-serif;
  margin: 0;
  padding: 0;
  display: flex;
  min-height: 100vh;
  flex-direction: column;
 
}

.landing-page {
  
  
 
  padding: 20px;
}

.header {
  background-color: rgba(213, 213, 213, 0.558);
  color: #fff;
  text-align: center;
  padding: 20px;
  margin-top: -1.5rem;
  margin-left: -1.5rem;
  margin-right: -1.5rem;
  padding-bottom: 4.6rem;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.203);
}

.header h1 {
  margin: 0;
  color: #ffffff;
}

.main-content {
  margin-top: 20px;
}

.cta-button {
  background-color: #232323;
  color: #fff;
  padding: 10px 20px;
  
  cursor: pointer;
  margin-top: 0.5rem;
  border-radius: 20px;
}

.cta-button:hover {
  background-color: #00000070;
}

.cta-link {
  text-decoration: none;
  color: #fff;
}
.footer{
  background-color: #000;
  color: #fff;
  text-align: center;
  font-size: 13px;
}
.footer1{
  background-color: #000;
  color: #fff;
  padding: 30px;
  margin-bottom: -1.5rem;
  margin-left: -1.5rem;
  margin-right: -1.5rem;
  margin-top: 3rem;
}


.logo-cont {
 
}

.logo2 {
  width: 14rem;
  position: fixed;
  top: 20px;
  
  opacity: 1; /* Ensuring logo is initially visible */
}

.hide-logo {
  opacity: 0; /* Setting opacity to 0 to hide the logo */
}


/* Add these styles to your existing stylesheet or create a new one */

.card-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.card {
  color: #000;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 16px;
  margin: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: #fff;
}

/* Optional: Add hover effect */
.card:hover {
  transform: translateY(-4px);
  transition: transform 0.1s ease-in-out;
}

/* Optional: Add some spacing between cards */
.card + .card {
  margin-top: 16px;
}
.button-top-text{
  color: #000;
}
.c1{
  color: rgb(190, 0, 0);
   font-family: 'LemonMilkProUltraLight';
  
}

.iqaco{
  font-family: 'LemonMilkProUltraLight';
  color: rgb(0, 0, 0);
  font-size: 16.5px;
  margin-bottom: -17px;
  margin-top: 30px;
  
}
.title{
  font-family: 'LemonMilkPro';
  color: rgb(190, 0, 0);
  font-size: 23.4px;
}
@font-face {
 font-family: 'LemonMilkPro';
  src: url('path/to/lemonmilkpro.woff') format('woff');
  
}

@font-face {
 font-family: 'LemonMilkProUltraLight';
  src: url('path/to/LemonMilkProUltraLight.woff') format('woff');
  
}
/* Slider container styles */
.slider-container {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
   display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  margin-top: 20px; /* Adjust as needed */
}

/* Individual slider image styles */
.slider {
 
  width: 100%;
  height: auto;
  transition: transform 0.5s ease-in-out; /* Add smooth transition effect */
}
.slider {
  display: flex;
  transition: transform 0.5s ease-in-out;
}

.slider img {
  width: 100%;
  flex-shrink: 0;
}
.quality{
  width:190px;
  
}
.quality-container{
  display: flex;
  justify-content: center;
  align-items: center;
  }

/* LandingPage.css */

.contact-section {
  margin-bottom: 20px;
}

.get-now-button {
  background-color: #232323;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 50px;
  cursor: pointer;
}

.get-now-button:hover {
  background-color: #00000070;
}
/* Add this CSS to your existing stylesheet or create a new one */

.landing-page {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.land-content {
  flex: 1; /* This will make the main content area take up the remaining space */
  overflow-y: auto; /* Enable vertical scroll if content overflows */
  padding: 10px;
  position: relative; /* Ensure positioning context for absolute positioning of header and footer */
}

/* Media query for desktop screens */
@media (min-width: 768px) {
  .land-content {
    width: 60%; /* Set a fixed width */
    margin-left: auto; /* Center the content horizontally */
    margin-right: auto; /* Center the content horizontally */
  }
}


.logo2 {
  width: 14rem;
}

/* Enable scrolling */
body::-webkit-scrollbar {
  display: none;
}

body {
  overflow: auto;
}
